import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const endpoint = 'contratos'

export const getAll = createAsyncThunk(`${endpoint}/all`, async params => {
  const { cliente_id, ...otherParams } = params;

  let response;
  if (cliente_id) {
    response = await axios.get(`${endpoint}`, { params: { cliente_id, ...otherParams } });
  } else {
    response = await axios.get(`${endpoint}`, { params: otherParams });
  }

  return {
    data: response.data.data,
    total: response.data.total,
    last_page: response.data.meta.last_page,
    report: response.data.report,
    params
  }
});

export const get = createAsyncThunk(`${endpoint}/get`, async id => {
  const response = await axios.get(`${endpoint}/${id}`, { id })
  return response.data.data
})

export const getCloseBusiness = createAsyncThunk(`${endpoint}/closeBusiness`, async (orcamentoId, { rejectWithValue }) => {
  try {
    const response = await axios.get(`${endpoint}/${orcamentoId.orcamentoId}/close_business`)
    return response.data
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
});

export const add = createAsyncThunk(`${endpoint}/add`, async (item, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.post(endpoint, item)
    return { data: response.data.data }
  } catch (e) {

    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }

  return item
})

export const update = createAsyncThunk(`${endpoint}/update`, async (item, { dispatch }) => {
 const response = await axios.put(`${endpoint}/${item.id}`, item)
  return response.data.data
})

export const updateImgInvite = createAsyncThunk(`${endpoint}/updateImg`, async (item, { dispatch }) => {
  item._method = 'PUT'
  await axios.postForm(`${endpoint}/${item.id}`, item)
  return item
})

export const updateStatus = createAsyncThunk('status', async () => {
  return 'READY'
})

export const updateGuest = createAsyncThunk(`${endpoint}/updateGuest`, async (item) => {
  await axios.put(`divulgacao/${item.id}`, item)
  return item
})

export const divulgacao = createAsyncThunk(`/divulgacao`, async (item) => {
  item._method = 'POST';
  const response = await axios.post(`/divulgacao`, item)
  return response.data
})

export const insertInvoices = createAsyncThunk(`${endpoint}/insert_invoices`, async (item, { dispatch }) => {
  try {
    const response = await axios.post(`${endpoint}/${item.contrato_id}/insert_invoices`, item)
    return { data: response.data }
  } catch (e) {

    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }
})

export const generatePdf = createAsyncThunk(`${endpoint}/generatepdf`, async (item, { dispatch }) => {
  try {
    const response = await axios.post(`${endpoint}/generatepdf`, item)
    return { data: response.data }
  } catch (e) {

    if (!e.response) {
      throw e
    }

    return rejectWithValue(e.response.data)
  }
})

export const downloadPdf = createAsyncThunk(`${endpoint}/downloadpdf`, async (item) => {
  const response = await axios.get(`${endpoint}/downloadpdf/${item.id}`, { responseType: 'blob' })
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', item.arquivo);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
})

export const uploadPdf = createAsyncThunk('/uploadPdf', async ({ id, file }) => {
    const formData = new FormData();
    formData.append('upload_arquivo', file);
    formData.append('_method', 'PUT'); 

    try {
      const response = await axios.post(`/contratos/${id}`, formData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Erro ao fazer upload do arquivo');
    }
  }
);

export const downloadCSV = createAsyncThunk(`${endpoint}/downloadcsv`, async () => {
  const response = await axios.get(`${endpoint}/export/cvs`, { responseType: 'blob' });
  const csvURL = URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `contratos.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
});

export const uploadCSV = createAsyncThunk(`${endpoint}/uploadcsv`, async (file, { rejectWithValue }) => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    await axios.post(`${endpoint}/import/cvs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data.message)
  }
});

export const downloadExample = createAsyncThunk(`${endpoint}/downloadExemplo`, async () => {
  const response = await axios.get(`/download/exemplo/contrato`, { responseType: 'blob' })
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  const fileName = 'exemplo_contrato.xlsx';
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
});

export const removePdf = createAsyncThunk(`${endpoint}/deletepdf`, async (id, { dispatch }) => {
  await axios.delete(`${endpoint}/deletepdf/${id}`)
})

export const remove = createAsyncThunk(`${endpoint}/remove`, async (id, { dispatch }) => {
  await axios.delete(`${endpoint}/${id}`)
  await dispatch(getAll())
})

export const removeMultiples = createAsyncThunk(`${endpoint}/removeMultiples`, async (ids, { dispatch }) => {
  await axios.delete(`${endpoint}/delete-multiples`, { data: { ids } })
})

export const removeGuest = createAsyncThunk(`${endpoint}/guest`, async (id, { dispatch }) => {
  await axios.delete(`divulgacao/${id}`)
})

export const removeMultiplesParticipants = createAsyncThunk(`${endpoint}/removeMultiplesParticipants`, async ({ ids, uri_convite }) => {
  await axios.delete('/divulgacoes/delete-multiples', {
    data: {
      ids: ids,
      uri_convite: uri_convite
    }
  })
})

export const downloadInvitesCSV = createAsyncThunk(`divulgacao/downloadInvite`, async (id) => {
  const response = await axios.get(`/divulgacao/export/cvs/${id}`, { responseType: 'blob' });
  const csvURL = URL.createObjectURL(response.data);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `lista-convidados.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
});

export const contractsSlice = createSlice({
  name: endpoint,
  initialState: {
    data: [],
    closeBusiness: {},
    message: '',
    status: 'READY',
    total: 0,
    params: {},
    error: '',
    last_page: 1,
    selected: {},
    report: {
      total: 0,
      totalFestas: 0,
      ticketMedio: 0,
      totalConvidados: 0,
      nps: 0
    }
  },
  reducers: {
    clearSelected: (state, payload) => {
      state.selected = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAll.fulfilled, (state, action) => {
        state.data = action?.payload?.data
        state.params = action?.payload?.params
        state.total = action?.payload?.total
        state.last_page = action?.payload?.last_page
        state.report = action?.payload?.report
        state.status = 'SUCCESS'
      })
      .addCase(getAll.pending, (state) => {
        state.loading = true
        state.status = 'PENDING'
      })
      .addCase(getAll.rejected, (state, action) => {
        state.loading = false
        state.data = []
        state.error = action.payload || 'Não foi possível carregar os dados'
        state.status = 'ERROR'
      })
      .addCase(get.pending, (state, action) => {
        state.status = 'PENDING'
        state.selected = action.payload
      })
      .addCase(get.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload
      })
      .addCase(get.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.selected = action.payload
      })
      .addCase(getCloseBusiness.pending, (state, action) => {
        state.status = 'PENDING'
      })
      .addCase(getCloseBusiness.rejected, (state, action) => {
        state.status = 'ERROR'
        state.error = action.payload.message
      })
      .addCase(getCloseBusiness.fulfilled, (state, action) => {
        state.status = 'SUCCESS'
        state.closeBusiness = action.payload
      })
      .addCase(add.fulfilled, (state, action) => {
        state.status = 'SAVE_SUCCESS'
        state.message = 'Salvo com sucesso'
        state.selected = action.payload.data
      })
      .addCase(add.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = action.payload.message || 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(update.fulfilled, (state, action) => {
        state.status = 'UPDATED_SUCCESS'
        state.message = 'Atualizado com sucesso'
      })
      .addCase(update.rejected, (state, action) => {
        state.status = 'SAVE_ERROR'
        state.message = 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(updateImgInvite.fulfilled, (state, action) => {
        state.status = 'UPDATED_SUCCESS_IMG'
        state.message = 'Imagem atualizada com sucesso'
      })
      .addCase(updateImgInvite.rejected, (state, action) => {
        state.status = 'UPDATED_ERROR_IMG'
        state.message = 'Não foi possível salvar'
        state.loading = false
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.status = 'READY'
        state.message = ''
      })
      .addCase(updateGuest.fulfilled, (state, action) => {
        state.status = 'UPDATED_GUEST_SUCCESS'
        state.message = 'Atualizado com sucesso'
      })
      .addCase(updateGuest.rejected, (state, action) => {
        state.status = 'UPDATED_GUEST_ERROR'
        state.message = 'Não foi possível atualizar'
        state.loading = false
      })
      .addCase(divulgacao.fulfilled, (state, action) => {
        state.status = 'SAVE_GUEST_SUCCESS'
        state.message = 'Convidado adicionado com sucesso'
      })
      .addCase(divulgacao.rejected, (state, action) => {
        state.status = 'SAVE_GUEST_ERROR'
        state.message = 'Não foi possível adicionar'
        state.loading = false
      })
      .addCase(remove.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(remove.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
      })
      .addCase(remove.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
      })
      .addCase(removeMultiples.rejected, (state, action) => {
        state.status = 'REMOVE_ERROR'
      })
      .addCase(removeMultiples.fulfilled, (state, action) => {
        state.status = 'REMOVE_SUCCESS'
      })
      .addCase(removeMultiples.pending, (state, action) => {
        state.status = 'REMOVE_PENDING'
      })
      .addCase(removeMultiplesParticipants.rejected, (state, action) => {
        state.status = 'REMOVE_PARTICIPANTS_ERROR'
      })
      .addCase(removeMultiplesParticipants.fulfilled, (state, action) => {
        state.status = 'REMOVE_PARTICIPANTS_SUCCESS'
      })
      .addCase(removeMultiplesParticipants.pending, (state, action) => {
        state.status = 'REMOVE_PARTICIPANTS_PENDING'
      })
      .addCase(removePdf.fulfilled, (state, action) => {
        state.status = 'REMOVE_PDF_SUCCESS'
      })
      .addCase(removePdf.pending, (state, action) => {
        state.status = 'REMOVE_PDF_PENDING'
      })
      .addCase(removeGuest.rejected, (state, action) => {
        state.status = 'REMOVE_GUEST_ERROR'
      })
      .addCase(removeGuest.fulfilled, (state, action) => {
        state.status = 'REMOVE_GUEST_SUCCESS'
      })
      .addCase(insertInvoices.fulfilled, (state, action) => {
        state.status = 'SAVE_INVOICES_SUCCESS'
        state.message = action.payload.message
      })
      .addCase(insertInvoices.rejected, (state, action) => {
        state.status = 'SAVE_INVOICES_ERROR'
        state.message = 'Não foi possível criar faturas'
      })
      .addCase(generatePdf.fulfilled, (state, action) => {
        state.status = 'SAVE_PDF_SUCCESS'
        state.message = action.payload.message
      })
      .addCase(generatePdf.rejected, (state, action) => {
        state.status = 'SAVE_PDF_ERROR'
        state.message = 'Não foi possível gerar o arquivo!'
      })
      .addCase(downloadPdf.fulfilled, (state, action) => {
        state.status = 'DOWNLOAD_PDF_SUCCESS'
        state.message = 'Download feito com sucesso!'
      })
      .addCase(downloadPdf.rejected, (state, action) => {
        state.status = 'DOWNLOAD_PDF_ERROR'
        state.message = 'Não foi possível fazer o download do arquivo!'
      })
      .addCase(downloadCSV.fulfilled, (state, action) => {
        state.status = 'DOWNLOAD_CSV_SUCCESS'
        state.message = 'Download feito com sucesso!'
      })
      .addCase(downloadCSV.rejected, (state, action) => {
        state.status = 'DOWNLOAD_CSV_ERROR'
        state.message = 'Não foi possível fazer o download do arquivo!'
      })
      .addCase(uploadCSV.fulfilled, (state, action) => {
        state.status = 'UPLOAD_CSV_SUCCESS'
        state.message = 'Upload feito com sucesso!'
      })
      .addCase(uploadCSV.rejected, (state, action) => {
        state.status = 'UPLOAD_CSV_ERROR'
        state.message = action.payload
      })
      .addCase(uploadPdf.fulfilled, (state, action) => {
        state.status = 'UPLOAD_PDF_SUCCESS'
        state.message = 'Upload feito com sucesso!'
      })
      .addCase(uploadPdf.rejected, (state, action) => {
        state.status = 'UPLOAD_PDF_ERROR'
        state.message = action.payload
      })
      .addCase(downloadExample.fulfilled, (state, action) => {
        state.status = 'DOWNLOAD_CSV_SUCCESS'
        state.message = 'Download feito com sucesso!'
      })
      .addCase(downloadExample.rejected, (state, action) => {
        state.status = 'DOWNLOAD_CSV_ERROR'
        state.message = 'Não foi possível fazer o download do arquivo!'
      })
      .addCase(downloadInvitesCSV.fulfilled, (state, action) => {
        state.status = 'DOWNLOAD_INVITE_SUCCESS'
        state.message = 'Convites exportados com sucesso!'
      })
      .addCase(downloadInvitesCSV.rejected, (state, action) => {
        state.status = 'DOWNLOAD_INVITE_ERROR'
        state.message = 'Não foi possível exportar o arquivo!'
      })
  }
})

export const { clearSelected } = contractsSlice.actions
export default contractsSlice.reducer
